import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../styles/colors';

export const MainContainer = styled.div`
  display: block;
  position: relative;
  margin-top: 220px;
  @media (max-width: 1399px) {
    margin-top: 210px;
  }
  @media (max-width: 1199px) {
    margin-top: 310px;
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
`;

export const LeftCol = styled(Col)`
  height: 320px;
  width: 60%;
  line-height: 300px;
  padding: 0 50px 0 50px;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(14, 76, 128, 1) 0%,
    rgba(11, 40, 71, 1) 100%
  );
  @media (max-width: 1399px) {
    height: 250px;
    line-height: 230px;
  }
  @media (max-width: 1199px) {
    width: 50%;
  }
  @media (max-width: 991px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    background: linear-gradient(
      to bottom,
      rgba(11, 40, 71, 1) 0%,
      rgba(14, 76, 128, 1) 100%
    );
    width: 100%;
    height: auto;
    padding: 30px 35px 50px 35px;
    line-height: 22px;
  }
`;

export const HeaderDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 80px;
`;

export const Header = styled.h1`
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 45px;
  line-height: 45px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 1199px) {
    font-size: 32px;
    line-height: 32px;
  }
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 22px;
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 75%;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background: ${AppColors.Green};
    border-radius: 4px;
  }
`;

export const RightCol = styled(Col)`
  height: 320px;
  width: 40%;
  border-left: 4px solid #fff;
  background-repeat: no-repeat;
  background-position: top center;
  background: url(${(props) => props.$background}) no-repeat center center;
  background-size: cover;
  @media (max-width: 1399px) {
    height: 250px;
  }
  @media (max-width: 1199px) {
    width: 50%;
  }
  @media (max-width: 991px) {
    width: 40%;
  }
`;
