import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import CommonBanner from '../../common/commonBanner/commonBanner';
import * as Styled from './locationDetailsPageTemplateStyle';
import GoogleMapReact from 'google-map-react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import iconPinBranch from '../../images/icon-map-pin-branch.png';
import iconPinCardlock from '../../images/icon-map-pin-cardlock-um.png';
import iconPinPipeline from '../../images/icon-map-pin-cardlock-pc.png';
import iconPinBoth from '../../images/icon-map-pin-branch-cardlock.png';
import LocationMarker from '../../components/locationMarker/locationMarker';
import { LocationTypes } from '../../constants';
import BannerDefault from '../../images/banner-default.png';
import Cookies from 'universal-cookie';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useTranslation } from 'react-i18next';
import Footer from '../../common/footer/footer';
import PropanePopup from '../../components/propanePopup/propanePopup';
import GlobalStyles from '../../styles/globalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../../common/header/header';
import { Helmet } from 'react-helmet';
import { addTrailingSlash, isExternalUrlHref } from '../../utils';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import logoSparlings from '../../images/logo-sparlings-og.png';

const LocationDetailsPageTemplate = ({ location }) => {
  const locationDataQuery = useStaticQuery(graphql`
    query locationDetailsDataQuery {
      allContentfulLocation(
        filter: { brand: { brand: { in: ["Sparlings"] } } }
      ) {
        edges {
          node {
            node_locale
            address1
            branchHours
            cardlockHours
            branchManager
            id
            location
            city
            province
            urlSlug
            postalCode
            latitude
            longitude
            phone
            email
            type {
              type
            }
            pinType {
              type
            }
            summerHours
            serviceArea {
              serviceArea
            }
            hasHeatingOil
            hasDiesel
            hasGasoline
            hasPropane
            hasLubricants
            hasHeatingEquipment
            hasDef
            hasMarkedDiesel
            hasRestaurant
            hasRestrooms
            hasShowers
            hasWifi
            hasParking
            hasMultiServices
            hasLunchCounter
            hasDriversLounge
            hasConvenienceStore
            pageTitle
            testimonial {
              raw
            }
            brand {
              brand
              creditColour
              creditText {
                creditText
              }
              creditLogo {
                file {
                  url
                }
              }
            }
            pageTitleDetails
          }
        }
      }
      allContentfulSparlings(
        filter: { identifier: { type: { eq: "locationDetails" } } }
      ) {
        edges {
          node {
            name
            title
            slug
            node_locale
            identifier {
              type
            }
            metadata
            sections {
              ... on ContentfulGenericSection {
                id
                title
                type
                listSection {
                  ... on ContentfulContent {
                    id
                    title
                    type
                    header
                    contentDetails {
                      raw
                    }
                  }
                  ... on ContentfulListOfImageWithPath {
                    id
                    title
                    type
                    listOfImagesWithPath {
                      title
                      type
                      image {
                        file {
                          url
                        }
                      }
                      path
                      richText {
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  let phones;
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [showPropaneModal, setShowPropaneModal] = React.useState(false);
  const cookies = new Cookies();
  const [translation] = useTranslation();
  const extractedUrlSlug = location?.pathname?.split('/');
  const urlSlug = extractedUrlSlug[extractedUrlSlug?.indexOf('details') + 1];
  const locationDataEdge =
    locationDataQuery?.allContentfulLocation?.edges?.filter((edge) =>
      cookies.get('language')
        ? edge?.node?.node_locale === cookies.get('language')
        : edge?.node?.node_locale === 'en-US'
    );

  let locationData;
  const res = locationDataEdge?.find((data) => data?.node?.urlSlug === urlSlug);

  res ? (locationData = res?.node) : navigate('/locations', { replace: true });

  const regionLanguageFilteredData =
    locationDataQuery?.allContentfulSparlings?.edges?.find((edge) =>
      cookies.get('language')
        ? edge?.node?.node_locale === cookies.get('language')
        : edge?.node?.node_locale === 'en-US'
    );
  const locationCommonData = regionLanguageFilteredData?.node?.sections;

  const productSupplierList = locationCommonData?.filter(
    (section) => section?.type === 'productSupplier'
  )[0].listSection;

  const productSupplierData = productSupplierList?.filter(
    (section) => section?.type === 'productSupplierInCityContent'
  )[0];

  const alsoAvailableData = productSupplierList?.filter(
    (section) => section?.type === 'textWithoutImage'
  )[0];

  const serviceAreasData = productSupplierList?.filter(
    (section) => section?.type === 'contentText'
  )[0];

  const productSupplierImages = productSupplierList?.filter(
    (section) => section?.type === 'locationAddressProductSupplierImages'
  )[0]?.listOfImagesWithPath;

  const dieselImage = productSupplierImages?.filter(
    (image) => image?.type === 'dieselImageWithPath'
  )[0];

  const propaneImage = productSupplierImages?.filter(
    (image) => image?.type === 'propaneImageWithPath'
  )[0];

  const heatingOilImage = productSupplierImages?.filter(
    (image) => image?.type === 'heatingOilImageWithPath'
  )[0];

  const lubricantImage = productSupplierImages?.filter(
    (image) => image?.type === 'lubricantsImageWithPath'
  )[0];

  const defImage = productSupplierImages?.filter(
    (image) => image?.type === 'defImageWithPath'
  )[0];

  const servicesData = locationCommonData?.filter(
    (section) => section?.type === 'locationDetails'
  )[0]?.listSection;

  const servicesHeader = servicesData?.filter(
    (section) => section?.type === 'locationDetailsHeader'
  )[0];

  const servicesImageData = servicesData?.filter(
    (section) => section?.type === 'imagesWithoutPath'
  )[0]?.listOfImagesWithPath;

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    if (types?.length > 1) {
      if (markerIcon == null) {
        markerIcon = iconPinBoth;
      }
    } else {
      types.forEach((locationType) => {
        if (locationType?.type === LocationTypes.Branch) {
          if (markerIcon == null) {
            markerIcon = iconPinBranch;
          }
        }
        if (locationType?.type === LocationTypes.UltramarCardlock) {
          if (markerIcon == null) {
            markerIcon = iconPinCardlock;
          }
        }
        if (locationType?.type === LocationTypes.PipelineCardlock) {
          if (markerIcon == null) {
            markerIcon = iconPinPipeline;
          }
        }
      });
    }
    return markerIcon;
  };
  const getIcon = (locationType) => {
    if (locationType) {
      if (locationType?.length > 1) {
        return iconPinBoth;
      } else {
        switch (locationType[0]?.type) {
          case LocationTypes.Branch:
            return iconPinBranch;

          case LocationTypes.UltramarCardlock:
            return iconPinCardlock;

          case LocationTypes.PipelineCardlock:
            return iconPinPipeline;

          default:
            return iconPinBranch;
        }
      }
    }
  };
  const products = [
    {
      boolean: locationData?.hasDiesel,
      name: translation('Diesel'),
    },
    {
      boolean: locationData?.hasPropane,
      name: translation('Propane'),
    },
    {
      boolean: locationData?.hasHeatingOil,
      name: translation('HeatingOil'),
    },
    {
      boolean: locationData?.hasLubricants,
      name: translation('Lubricants'),
    },
    {
      boolean: locationData?.hasDef,
      name: translation('DefShort'),
    },
  ];
  const offerings = [
    {
      boolean: locationData?.hasHeatingOil,
      name: translation('HeatingOil'),
    },
    {
      boolean: locationData?.hasDiesel,
      name: translation('Diesel'),
    },
    {
      boolean: locationData?.hasMarkedDiesel,
      name: translation('MarkedDiesel'),
    },
    {
      boolean: locationData?.hasGasoline,
      name: translation('Gasoline'),
    },
    {
      boolean: locationData?.hasPropane,
      name: translation('Propane'),
    },
    {
      boolean: locationData?.hasLubricants,
      name: translation('Lubricants'),
    },
    {
      boolean: locationData?.hasHeatingEquipment,
      name: translation('HeatingEquipment'),
    },
    {
      boolean: locationData?.hasDef,
      name: translation('Def'),
    },
    {
      boolean: locationData?.hasShowers,
      name: translation('Showers'),
    },
    {
      boolean: locationData?.hasRestrooms,
      name: translation('Restroom'),
    },
    {
      boolean: locationData?.hasWifi,
      name: translation('Wi-Fi'),
    },
    {
      boolean: locationData?.hasParking,
      name: translation('Parking'),
    },
    {
      boolean: locationData?.hasRestaurant,
      name: translation('Restaurant'),
    },
    {
      boolean: locationData?.hasConvenienceStore,
      name: translation('ConvenienceStore'),
    },
    {
      boolean: locationData?.hasLunchCounter,
      name: translation('LunchCounter'),
    },
    {
      boolean: locationData?.hasDriversLounge,
      name: translation('DriversLounge'),
    },
    {
      boolean: locationData?.hasMultiServices,
      name: translation('MultiServices'),
    },
  ];
  const availableProducts = [
    {
      boolean: locationData?.hasGasoline,
      name: translation('Gasoline'),
    },
    {
      boolean: locationData?.hasMarkedDiesel,
      name: translation('MarkedDiesel'),
    },
    {
      boolean: locationData?.hasHeatingEquipment,
      name: translation('HeatingEquipment'),
    },
  ];
  const serviceAreas = locationData?.serviceArea?.serviceArea?.split(';');
  const [lat, setLat] = useState(parseFloat(locationData?.latitude));
  const [lng, setLng] = useState(parseFloat(locationData?.longitude));
  const [currentMarker, setCurrentMarker] = useState('');
  const [zoom, setZoom] = useState(10);

  const StringGenerator = (object) => {
    let header = '';
    let length = 0;
    object.map((item) => {
      if (item?.boolean) {
        if (length !== 0) {
          header += ', ' + item?.name;
          length++;
        } else {
          header += item?.name;
          length++;
        }
      }
    });
    return header;
  };

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaTextSmall>{children}</Styled.ParaTextSmall>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.SubHeader>{children}</Styled.SubHeader>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const optionsTestimonialStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.MiddleText>{children}</Styled.MiddleText>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.SubHeader>{children}</Styled.SubHeader>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const optionsProductSupplierStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ProductSupplier>{children}</Styled.ProductSupplier>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.SubHeader>{children}</Styled.SubHeader>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const servicesMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ServicesText>{children}</Styled.ServicesText>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <>
      <GlobalStyles />
      <Helmet
        htmlAttributes={{
          lang: regionLanguageFilteredData?.node?.node_locale || 'en',
        }}
      >
        <title>
          {regionLanguageFilteredData?.node?.title
            ? regionLanguageFilteredData?.node?.title + ' | Sparlings Propane'
            : 'Energy Solutions for Residential and Businesses | Sparlings Propane'}
        </title>
        <meta name="twitter:image" content={logoSparlings}></meta>
        <meta property="og:image" content={logoSparlings}></meta>
      </Helmet>
      <Header />
      <CommonBanner Header={locationData?.pageTitle} ImageUrl={BannerDefault} />
      <Styled.SectionContainer>
        <Row className="g-0">
          <Styled.TopLeftCol xs={12} xl={6}>
            <Styled.TopHeader>
              <Styled.IconImage
                src={getIcon(locationData?.pinType)}
                alt="Pin Icon"
              />
              {locationData?.location}
            </Styled.TopHeader>
            <Row className="g-0">
              <Col xs={12} md={6}>
                <Styled.ParaText>
                  {locationData?.address1}
                  <br />
                  {locationData?.city},&nbsp;{locationData?.province}&nbsp;
                  {locationData?.postalCode}
                  <br />
                  <br />
                  <Styled.LinkStyle
                    target="_blank"
                    href={`https://www.google.com/maps/dir/?api=1&destination=
                      ${locationData?.address1}, ${locationData?.location}, ${locationData?.province}, ${locationData?.postalCode}`}
                  >
                    Get Directions
                  </Styled.LinkStyle>
                </Styled.ParaText>

                <Styled.SubTitle>Branch Manager:</Styled.SubTitle>
                <Styled.ParaText>{locationData?.branchManager}</Styled.ParaText>

                <Styled.SubTitle>Branch Hours:</Styled.SubTitle>
                <Styled.ParaText>{locationData?.branchHours}</Styled.ParaText>
                <br />
              </Col>
              <Col>
                <Styled.SubTitle>Contact:</Styled.SubTitle>
                <Styled.ParaText>
                  <Styled.LinkStyle
                    tabindex="0"
                    href={`mailto:${locationData?.email}`}
                  >
                    {locationData?.email}
                  </Styled.LinkStyle>
                  {
                    ((phones = locationData?.phone.split(' ')),
                    phones?.map((phone, index) => (
                      <Styled.LinkStyle
                        key={index}
                        href={`tel:${phone}`}
                        tabindex="0"
                      >
                        {phone}
                      </Styled.LinkStyle>
                    )))
                  }
                </Styled.ParaText>

                <Styled.SubTitle>Contact Online:</Styled.SubTitle>

                <Styled.AnchorButton
                  href={addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + '/for-home/contact-us'
                  )}
                >
                  For Home
                </Styled.AnchorButton>

                <br />
                <Styled.AnchorButton
                  href={addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + '/for-business/contact-us'
                  )}
                >
                  For Business
                </Styled.AnchorButton>
              </Col>
            </Row>
          </Styled.TopLeftCol>
          <Styled.TopRightCol className="px-0" xs={12} xl={6}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                libraries: ['places', 'geometry'],
              }}
              center={{ lat, lng }}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals
            >
              {locationDataEdge?.map((location, index) => (
                <LocationMarker
                  allowPopup={false}
                  key={index}
                  id={location?.node?.id}
                  currentMarker={currentMarker}
                  lat={location?.node?.latitude}
                  showServices={true}
                  lng={location?.node?.longitude}
                  node={location?.node}
                  src={getMarkerIcon(location?.node?.pinType)}
                  onMarkerClick={(data) => {
                    setCurrentMarker(data?.id);
                    setLat(parseFloat(data?.lat));
                    setLng(parseFloat(data?.lng));
                  }}
                />
              ))}
            </GoogleMapReact>
          </Styled.TopRightCol>
        </Row>
      </Styled.SectionContainer>
      <Styled.SectionContainerMiddle>
        <Row>
          <Styled.MiddleLeftCol xs={12} xl={locationData?.serviceArea ? 6 : 12}>
            <Styled.Header style={{ textAlign: 'left' }}>
              {productSupplierData?.header
                .replace('{Products}', StringGenerator(products))
                .replace('{city}', locationData?.location)}
            </Styled.Header>
            {productSupplierData?.contentDetails &&
              documentToReactComponents(
                JSON.parse(productSupplierData?.contentDetails?.raw),
                optionsProductSupplierStyle
              )}
            {locationData.hasPropane && (
              <Styled.AnchorStyle
                onClick={() => {
                  setShowPropaneModal(true);
                }}
              >
                <Styled.ProductsImageStyle
                  src={propaneImage?.image?.file?.url}
                />
                {propaneImage?.title}
              </Styled.AnchorStyle>
            )}
            {locationData.hasDiesel &&
              (dieselImage?.path.trim() ? (
                <Styled.AnchorStyleInternal
                  to={
                    isExternalUrlHref(dieselImage?.path)
                      ? dieselImage?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + dieselImage?.path
                        )
                  }
                >
                  <Styled.ProductsImageStyle
                    src={dieselImage?.image?.file.url}
                  />
                  {dieselImage?.title}
                </Styled.AnchorStyleInternal>
              ) : (
                <Styled.LinkTypeStyle>
                  <Styled.ProductsImageStyle
                    src={dieselImage?.image?.file.url}
                  />
                  {dieselImage?.title}
                </Styled.LinkTypeStyle>
              ))}

            {locationData.hasHeatingOil &&
              (heatingOilImage?.path.trim() ? (
                <Styled.AnchorStyleInternal
                  to={
                    isExternalUrlHref(heatingOilImage?.path)
                      ? heatingOilImage?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + heatingOilImage?.path
                        )
                  }
                >
                  <Styled.ProductsImageStyle
                    src={heatingOilImage?.image?.file?.url}
                  />
                  {heatingOilImage?.title}
                </Styled.AnchorStyleInternal>
              ) : (
                <Styled.LinkTypeStyle>
                  <Styled.ProductsImageStyle
                    src={heatingOilImage?.image?.file?.url}
                  />
                  {heatingOilImage?.title}
                </Styled.LinkTypeStyle>
              ))}
            {locationData.hasLubricants &&
              (lubricantImage?.path.trim() ? (
                <Styled.AnchorStyleInternal
                  to={
                    isExternalUrlHref(lubricantImage?.path)
                      ? lubricantImage?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + lubricantImage?.path
                        )
                  }
                >
                  <Styled.ProductsImageStyle
                    src={lubricantImage?.image?.file?.url}
                  />
                  {lubricantImage?.title}
                </Styled.AnchorStyleInternal>
              ) : (
                <Styled.LinkTypeStyle>
                  <Styled.ProductsImageStyle
                    src={lubricantImage?.image?.file?.url}
                  />
                  {lubricantImage?.title}
                </Styled.LinkTypeStyle>
              ))}
            {locationData.hasDef && (
              <Styled.AnchorStyleInternal>
                <Styled.ProductsImageStyle src={defImage?.image?.file?.url} />
                {defImage?.title}
              </Styled.AnchorStyleInternal>
            )}
            {StringGenerator(availableProducts) && alsoAvailableData && (
              <Styled.ParaTextSmall>
                {documentToReactComponents(
                  JSON.parse(
                    alsoAvailableData?.contentDetails?.raw?.replace(
                      '{otherProducts}',
                      StringGenerator(availableProducts)
                    )
                  ),
                  optionsMainStyle
                )}
              </Styled.ParaTextSmall>
            )}

            <hr />
            <Styled.SubHeader style={{ textAlign: 'left' }}>
              Testimonials
            </Styled.SubHeader>
            <Styled.WhiteCard>
              {locationData?.testimonial?.raw &&
                documentToReactComponents(
                  JSON.parse(locationData?.testimonial?.raw),
                  optionsTestimonialStyle
                )}
            </Styled.WhiteCard>
          </Styled.MiddleLeftCol>
          {locationData?.serviceArea && (
            <Styled.MiddleRightCol xs={12} xl={6}>
              <Styled.Header style={{ textAlign: 'left' }}>
                {serviceAreasData?.header?.replace(
                  '{prductList}',
                  StringGenerator(products)
                )}
              </Styled.Header>

              <Styled.ParaText>{serviceAreas}</Styled.ParaText>
            </Styled.MiddleRightCol>
          )}
        </Row>
      </Styled.SectionContainerMiddle>
      <Styled.SectionContainer>
        <Styled.Header>
          {servicesHeader?.header
            ?.replace('{Products}', StringGenerator(products))
            ?.replace('{city}', locationData?.location)}
        </Styled.Header>
        <Row className="g-0">
          {servicesImageData?.map((item, index) => (
            <Styled.ServicesCol key={index} xs={12} md={6} xl={3}>
              <Styled.ServicesImage
                src={item?.image?.file?.url}
                alt={item?.title}
              />
              <Styled.SubHeader>{item?.title}</Styled.SubHeader>
              {item?.richText &&
                documentToReactComponents(
                  JSON.parse(item?.richText?.raw),
                  servicesMainStyle
                )}
            </Styled.ServicesCol>
          ))}
        </Row>
        <BackToTopScroll scrollPosition={scrollPosition} />
      </Styled.SectionContainer>
      <Footer />
      {showPropaneModal && <PropanePopup showModal={setShowPropaneModal} />}
    </>
  );
};
export default LocationDetailsPageTemplate;
