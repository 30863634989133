import { Link } from 'gatsby';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import IconArrowYellow from '../../images/icon-arrow-yellow-right.png';

export const SectionContainer = styled.div`
  display: block;
  padding: 60px 110px 80px 110px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 1199px) {
    padding: 60px 50px 50px 50px;
  }
  @media (max-width: 767px) {
    padding: 60px 35px 35px 35px;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const SectionContainerMiddle = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  display: block;
  padding: 60px 110px 80px 110px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 1199px) {
    padding: 60px 50px 50px 50px;
  }
  @media (max-width: 767px) {
    padding: 60px 35px 35px 35px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TopLeftCol = styled(Col)`
  padding: 0 45px 0 0 !important;
  @media (max-width: 1199px) {
    padding-right: 0 !important;
    padding-bottom: 55px !important;
  }
`;
export const TopRightCol = styled(Col)`
  position: relative;
  overflow: hidden;
  height: 450px;
`;
export const TopHeader = styled.h2`
  margin: 0 0 30px 0;
  position: relative;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #0e4c80;

  @media (max-width: 1399px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const IconImage = styled.img`
  width: 46px;
  height: auto;
  margin: -6px 15px 0 0;
`;

export const ParaText = styled.p`
  margin-bottom: 40 !important;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 26px;
  }
`;
export const ParaTextSmall = styled.p`
  margin-bottom: 0 !important;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  b {
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
  }
  font-style: italic;
  @media (max-width: 1399px) {
    font-size: 15px;
    line-height: 22px;
    b {
      font-size: 15px;
      line-height: 22px;
      font-style: normal;
    }
  }
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 20px;
    b {
      font-size: 13px;
      line-height: 20px;
      font-style: normal;
    }
  }
`;
export const LinkStyle = styled.a`
  display: block;
  color: #55aa4c;
  text-decoration: none;
`;
export const AnchorButton = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  font-family: 'Adelle-Bold', sans-serif;
  text-transform: uppercase;
  color: #55aa4c;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1399px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    padding: 10px 20px 6px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
  }
  :hover {
    top: -5px;
    color: #55aa4c;
    border-color: #55aa4c;
    box-shadow: 0 5px 0 0 #55aa4c;
  }
`;

export const Header3 = styled.h3`
  margin-bottom: 10px;
  font-family: 'HelveticaNeue-Bold', arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #00a6e8;
`;

export const Header = styled.h3`
  margin: 0 0 35px 0;
  text-align: center;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const ServicesCol = styled(Col)`
  padding: 0 25px 0 0 !important;
  text-align: center;
  display: block;
  @media (max-width: 1199px) {
    padding-bottom: 45px !important;
  }
  @media (max-width: 767px) {
    padding-right: 0 !important;
  }
`;
export const ServicesImage = styled.img`
  display: block;
  width: auto;
  height: 100px;
  margin: 15px auto 30px auto;
`;

export const SubHeader = styled.h4`
  margin-bottom: 30px;
  padding-bottom: 0;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0e4c80;
  border: none;
  font-weight: bold;
`;

export const ServicesText = styled.div`
  margin: 0 0 10px 0 !important;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  code {
    font-family: 'Figtree', arial, sans-serif;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 0 !important;
    font-style: italic;
    font-size: 16px !important;
    line-height: 24px !important;
    @media (max-width: 1399px) {
      font-size: 15px !important;
      line-height: 22px !important;
    }
    @media (max-width: 767px) {
      font-size: 13px !important;
      line-height: 20px !important;
    }
  }
  @media (max-width: 1399px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
export const MiddleLeftCol = styled(Col)`
  padding: 0 100px 0 0 !important;
  @media (max-width: 1199px) {
    padding-right: 0 !important;
    padding-bottom: 55px !important;
  }
`;
export const MiddleRightCol = styled(Col)`
  padding: 0 !important;
`;

export const MiddleText = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  b {
    font-family: 'Figtree', arial, sans-serif;
    font-style: normal;
  }
  i {
    font-style: italic;
    font-size: 16px;
  }
  @media (max-width: 1399px) {
    font-size: 16px;
    line-height: 20px;
    i {
      font-size: 15px;
    }
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
    i {
      font-size: 15px;
    }
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    i {
      font-size: 13px;
    }
  }
`;
export const ProductSupplier = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  b {
    font-family: 'Figtree', arial, sans-serif;
    font-style: normal;
  }
  i {
    font-style: italic;
    font-size: 16px;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
    i {
      font-size: 15px;
    }
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    i {
      font-size: 13px;
    }
  }
`;
export const AnchorStyleInternal = styled(Link)`
  text-decoration: none;
  display: inline-block;
  margin-right: 45px;
  margin-bottom: 45px;
  text-align: center;
  color: #55aa4c;
  :hover {
    color: #55aa4c;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
export const LinkTypeStyle = styled.p`
  text-decoration: none;
  display: inline-block;
  margin-right: 45px;
  margin-bottom: 45px;
  text-align: center;
  color: #55aa4c;
  :hover {
    color: #55aa4c;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
export const AnchorStyle = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-right: 45px;
  margin-bottom: 45px;
  text-align: center;
  color: #55aa4c;
  :hover {
    color: #55aa4c;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
export const ProductsImageStyle = styled.img`
  display: block;
  width: auto;
  height: 98px;
  margin: 0 auto 15px auto;
`;

export const ServiceAreasList = styled.ul`
  margin-bottom: 0 !important;
  padding: 0 !important;
  column-count: 2;
  @media (max-width: 1199px) {
    column-count: 3;
  }
  @media (max-width: 767px) {
    column-count: 1;
  }
`;

export const ListItems = styled.li`
  margin: 0;
  padding: 0 0 15px 30px;
  list-style: none;
  background: url(${IconArrowYellow}) no-repeat 0 9px;
  background-size: 10px auto;
`;

export const SubTitle = styled.h3`
  margin-bottom: 10px;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
`;

export const WhiteCard = styled.blockquote`
  background: #fff;
  width: 100%;
  padding: 60px;
  margin: 0 0 60px 0;
`;
